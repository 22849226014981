<!--
 * @Author: mulingyuer
 * @Date: 2021-04-09 17:49:59
 * @LastEditTime: 2021-09-09 10:09:35
 * @LastEditors: aleaner
 * @Description: 筛选
 * @FilePath: \vote\components\vote-list\Filter.vue
 * 怎么可能会有bug！！！
-->
<template>
  <layout-filter :onFilter="onFilter" :onReset="onReset" :onExport="onExport" :toggleCollapseEnable="false">
    <el-form :model="form" :rules="rules" ref="ruleForm" label-width="70px" size="medium" class="filter-from">
      <div class="filter-item">
        <el-form-item label="模板名称：" prop="keyword">
          <el-input v-model="form.keyword" placeholder="请输入" clearable></el-input>
        </el-form-item>
        <el-form-item label="分类名称：" prop="category_id">
          <el-select v-model="form.category_id">
            <el-option v-for="c in categories" :key="c.id" :label="c.name" :value="c.id" />
          </el-select>
        </el-form-item>
        <el-form-item label="模板状态：" prop="status">
          <el-select v-model="form.status">
            <el-option v-for="c in statusList" :key="c.id" :label="c.name" :value="c.id" />
          </el-select>
        </el-form-item>
      </div>
      <div class="filter-item">
        <DatePeriodPicker label="创建时间：" start-placeholder="开始日期" :start-time.sync="form.create_start_time"
          start-prop="create_start_time" end-placeholder="结束日期" :end-time.sync="form.create_end_time" end-prop="create_end_time" />
      </div>
    </el-form>
  </layout-filter>
</template>
<script>
import LayoutFilter from "@/base/layout/LayoutFilter";
import DatePeriodPicker from "@/base/components/Form/DatePeriodPicker";
import { allCategory } from "@/modules/approval/api/category-list";
// import { exportDocument } from "../../api/vote-list";
export default {
  name: 'approval-template-filter',
  props: {
    //筛选回调
    filterChange: {
      required: true,
      type: Function,
    },
    //导出要用的数据
    exportData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: {
        "page": 1,
        "page_size": 10,
        "order_by": "create_time",
        "is_desc": 1,
        "tab": "normal", // normal、deleted
        "keyword": "", // 名称关键字
        "category_id": "", // 分类 ID
        "status": -1, // -1:全部，0:禁用，1:启用
        "create_start_time": -1,
        "create_end_time": -1
      },
      rules: {},
      categories: [],
      statusList: [
        {
          id: -1,
          name: "全部"
        },
        {
          id: 0,
          name: "禁用"
        },
        {
          id: 1,
          name: "启用"
        }
      ]
    };
  },
  created() {
    allCategory().then(res => {
      this.categories = res.data
    }).catch(() => {
    })
  },
  methods: {
    //筛选
    onFilter() {
      return this.filterChange(Object.assign({}, this.form));
    },
    //重置
    onReset() {
      this.$refs["ruleForm"].resetFields();
      this.onFilter();
    },
    //导出
    onExport() {
      // return exportDocument({ ...this.exportData, ...this.form }).catch(
      //   (err) => {
      //     // console.log(err);
      //   }
      // );
    },
  },
  components: {
    LayoutFilter,
    DatePeriodPicker,
  },
};
</script>
<style lang="scss" scoped>
</style>
