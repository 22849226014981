<template>
  <div>
    <list-layout ref="listLayout"
                 :filter-form="filterForm"
                 :tab-arr="tabArr"
                 :thead="thead"
                 :on-fetch="getList"
                 :on-delete="onDelete"
                 :on-put-back="onPutBack"
                 :on-toggle="toggleItem"
                 @command="handleOperation"
    >
      <template slot="top">
        <el-button
            class="top-btn"
            type="primary"
            size="small"
            @click="toAdd"
        >添加</el-button
        >
      </template>
      <template #filter>
        <approval-template-filter :export-data="{}" :filter-change="onFilter" />
      </template>
    </list-layout>
  </div>
</template>

<script>
import ListLayout from "@/base/layout/ListLayout";

import {
  deleteTemplate,
  getTemplateList,
  moveTemplateFromDeleted, moveTemplateToDeleted,
  updateTemplateStatus
} from "@/modules/approval/api/template-list";
import ApprovalTemplateFilter from "@/modules/approval/components/template-list/Filter";

export default {
  components: {ApprovalTemplateFilter, ListLayout},
  data() {
    return {
      filterForm: {
        tab: "normal"
      },
      tabArr: [
        { label: "全部", name: "normal" },
        { label: "回收站", name: "deleted" },
      ],
      thead: [
        {
          type: 'selection',
          fixed: "left"
        },
        {
          label: "ID",
          prop: "id",
          width: 100
        },
        {
          label: '图标',
          prop: 'icon',
          type: 'icon'
        },
        {
          label: '模板名称',
          prop: 'name'
        },
        {
          label: '分类',
          prop: 'category_name'
        },
        {
          label: '模板状态',
          prop: 'status',
          type: 'checkbox'
        },
        {
          label: '创建时间',
          prop: 'create_time',
          sortable: true,
          minWidth: 150
        },
        {
          label: '操作',
          type: 'operation',
          // width: 135,
          width: 175,
          operations: [
            {command: 'edit', text: '编辑', visible: (r, q) => q.tab !== 'deleted'},
            {command: 'record', text: '申请记录'},
            // {command: 'disable', text: '禁用'},
            {command: 'putback', visible: (r, q) => q.tab === 'deleted'},
            {command: 'delete'},
          ]
        }
      ],
      showAddCategory: false,
      editObj: {},
      showTransfer: false,
      transferId: "",
    }
  },
  methods: {
    getList(q = {}) {
      return getTemplateList({...this.filterForm, ...q})
    },
    // 刷新列表
    refreshList() {
      this.$refs.listLayout.getList();
    },
    toggleItem(data) {
      return updateTemplateStatus(data)
    },
    updateData() {
      this.showAddCategory = false;
      this.refreshList();
    },
    closeAddDialog() {
      this.showAddCategory = false;
      this.editObj = {};
    },
    // 数据删除
    onDelete(idList, forever = false) {
      return forever ? deleteTemplate(idList) : moveTemplateToDeleted(idList);
    },
    onPutBack(idList) {
      return moveTemplateFromDeleted(idList)
    },
    handleOperation(e) {
      switch(e.command) {
        case 'edit': {
          this.$router.push({
            name: 'ApprovalTmplDetail',
            params: {
              id: e.row.id
            }
          })
          break
        }
        case 'record': {
          this.$router.push({
            name: 'ApprovalTmplRecord',
            params: {
              id: e.row.id
            }
          })
          break
        }
        default:
      }
    },
    toAdd() {
      this.$router.push({
        name: 'ApprovalTmplAddNew'
      })
    },
    // 筛选回调
    onFilter(filterObj) {
      Object.assign(this.filterForm, filterObj, { page: 1 });
      this.$refs.listLayout.getList();
    },
  },
}
</script>

<style lang="scss" scoped>

</style>

